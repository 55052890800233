export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard',
    resource: 'Pharmacist',
    action: 'read',
  },
  {
    title: 'My Records',
    icon: 'UserIcon',
    children: [
      {
        title: 'History',
        route: 'apps-users-list',
      },
      {
        title: 'Reports',
        route: 'apps-users-list',
      },
      {
        title: 'Appointments',
        route: 'apps-users-list',
      },
    ],
  },
]
