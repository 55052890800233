export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard-pharmacy',
    resource: 'Pharmacist',
    action: 'read',
  },
  {
    title: 'My Patients',
    icon: 'UserIcon',
    resource: 'Pharmacist',
    action: 'read',
    children: [
      {
        title: 'Patient List',
        route: 'pharmacy-patients-list',
        resource: 'Pharmacist',
        action: 'read',
      },
    ],
  },
]
