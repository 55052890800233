export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard-admin',
    resource: 'Pharmacist',
    action: 'read',
  },
  {
    header: 'Reports',
  },
  {
    title: 'Reports',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Reports',
        route: 'apps-users-list',
      },
    ],
  },
  {
    header: 'Settings',
  },

  {
    title: 'Users',
    icon: 'UserIcon',
    children: [
      {
        title: 'Admin Users',
        route: 'system-admin-list',
      },

      {
        title: 'Pharmacists',
        route: 'system-pharmacist-list',
      },

      {
        title: 'CDEs',
        route: 'system-cde-list',
      },

      {
        title: 'HCPs',
        route: 'system-hcp-list',
      },

      {
        title: 'Patients',
        route: 'system-patient-list',
      },
    ],
  },
  {
    title: 'System Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Care Team',
        route: 'system-care-team-list',
      },

      {
        title: 'Complications',
        route: 'system-diabetes-complication-list',
      },

      {
        title: 'Diabetes Types',
        route: 'system-diabetes-type-list',
      },

      {
        title: 'Glucose Monitor',
        route: 'system-glucose-monitor-list',
      },

      {
        title: 'Hospitalisation',
        route: 'system-hospitalisation-list',
      },

      {
        title: 'Insulin',
        route: 'system-insulin-list',
      },

      {
        title: 'Insulin Pumps',
        route: 'system-insulin-pump-list',
      },

      {
        title: 'Libre Reasons',
        route: 'system-libre-reason-list',
      },

      {
        title: 'Medicines',
        route: 'system-diabetes-medicine-list',
      },

      {
        title: 'Medicine Brands',
        route: 'system-diabetes-medicine-brand-list',
      },

      {
        title: 'Pharmacy',
        route: 'system-pharmacy-list',
      },
    ],
  },
]
