import superAdmin from './super-admin'
import pharmacist from './pharmacist'
import cde from './cde'
import patient from './patient'
import others from './others'

let myMenu = [...others]
const user = JSON.parse(localStorage.getItem('userData'))
// super or admin
if (user.role === 1 || user.role === 2) {
  myMenu = [...superAdmin]
} else if (user.role === 3) {
  // pharmacist or cde or hcp
  myMenu = [...pharmacist]
} else if (user.role === 4 || user.role === 5) {
  // cde or hcp
  myMenu = [...cde]
} else if (user.role === 6) {
  myMenu = [...patient]
}
const menu = myMenu
export default menu
